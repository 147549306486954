import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
  Button,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { base_url } from '../base_url';

export const ModalDataDatatables = ({ data, getData }) => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [copy, setCopy] = useState(null);
  const [formData, setFormData] = useState({ user_name: '', password: '', end_date: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!copy) return; // Ensure there's a selected item to copy

    try {
      const response = await fetch(`${base_url}real_states/copy_real/${copy.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        getData()
        toast.success("Copied Successfully!", {
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px",
            width: "fit-content",
            fontWeight: "700",
          },
        });
        setImage(null); // Close the modal after success
      } else {
        toast.error("Failed to copy. Please try again.");
      }
    } catch (error) {
      console.error("Error copying data:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block">
            <DropdownToggle
              className="btn btn-soft-secondary btn-sm"
              tag="button"
            >
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                className="edit-item-btn"
                onClick={() => navigate("/RealEstate/EditRealEstate/" + cell?.id)}
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                Edit
              </DropdownItem>
              <DropdownItem
                className="remove-item-btn"
                onClick={() => setCopy(cell)}
              >
                Copy
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Link</span>,
      selector: (row) => (
        <CopyToClipboard
          text={"https://premiergroup.ahmed.its.ae/login"}
          onCopy={() => {
            toast.success("Copied Successfully", {
              icon: null,
              style: {
                boxShadow: "1px 2px 24px -2px grey",
                background: "black",
                color: "white",
                fontSize: "20px ",
                width: "fit-content",
                fontWeight: "700",
              },
            });
          }}
        >
          <span>https://premiergroup.ahmed.its.ae/login</span>
        </CopyToClipboard>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Image</span>,
      selector: (row) => (
        <img
          onClick={() => setImage(row?.image)}
          src={row?.image}
          alt=""
          style={{
            width: "140px",
            height: "50px",
            objectFit: "contain",
            padding: "10px",
            cursor: "pointer",
          }}
        />
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">description</span>,
      selector: (row) =>
        row.description && row.description?.length ? (
          <p
            dangerouslySetInnerHTML={{
              __html:
                row.description && row.description?.length
                  ? row.description
                  : "<p></p>",
            }}
          ></p>
        ) : null,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">location</span>,
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">End Date</span>,
      selector: (row) => row.end_date,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">user_name</span>,
      selector: (row) => (
        <CopyToClipboard
          text={row.user_name}
          onCopy={() => {
            toast.success("Copied Successfully", {
              icon: null,
              style: {
                boxShadow: "1px 2px 24px -2px grey",
                background: "black",
                color: "white",
                fontSize: "20px ",
                width: "fit-content",
                fontWeight: "700",
              },
            });
          }}
        >
          <span>{row.user_name}</span>
        </CopyToClipboard>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">password</span>,
      selector: (row) => (
        <CopyToClipboard
          text={row.password}
          onCopy={() => {
            toast.success("Copied Successfully", {
              icon: null,
              style: {
                boxShadow: "1px 2px 24px -2px grey",
                background: "black",
                color: "white",
                fontSize: "20px ",
                width: "fit-content",
                fontWeight: "700",
              },
            });
          }}
        >
          <span>{row.password}</span>
        </CopyToClipboard>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Create Date</span>,
      selector: (row) => row.created_at,
      sortable: true,
    },
  ];

  return (
    <>
      <DataTable columns={columns} data={data} pagination />
      <Modal isOpen={!!copy} toggle={() => setCopy(null)} centered>
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => setCopy(null)}
        >
          Copy Real-Estate
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="user_name">User Name</Label>
              <Input
                type="text"
                name="user_name"
                id="user_name"
                value={formData.user_name}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                type="password"
                name="password"
                id="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="end_date">End Date</Label>
              <Input
                type="date"
                name="end_date"
                id="end_date"
                value={formData.end_date}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
