import React, { useState } from "react";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Container } from "reactstrap";

// Rating Plugin
import Rating from "react-rating";
// import RatingTooltip from "react-rating-tooltip";

const UiRating = () => {
  document.title = "Rating | Premier Group ";

  const [def, setdef] = useState("");
  const [stopRate, setStopRate] = useState("");
  const [secondrate, setSecondRate] = useState("");
  const [startrate, setStartrate] = useState("");
  const [customize, setcustomize] = useState("");
  const starStyle = {};

  const tooltipContent = ["Rate 1", "Rate 2", "Rate 3", "Rate 4", "Rate 5"];
  const tooltipContentMore = ["1", "2", "3", "4", "5", "6", "7", "8"];
  const tooltipContentHalf = ["6", "7", "8", "9", "10"];
  const tooltipContentMiddle = [
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];
  const tooltipContentStep = ["2", "4", "6", "8", "10"];

  return (
    <React.Fragment></React.Fragment>
  );
};
export default UiRating;
