import React, { useEffect, useState } from "react";
import { ModalDataDatatables } from "./dataTable";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./style.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { base_url } from "../base_url";
function RealEstate() {
    const navigate = useNavigate();
    const [data, setData] = useState();
    const getData = ()=>{
      axios.get(base_url + "real_states/get_all_for_admin").then(res=>{
        setData(res?.result)
      })
    }

    useEffect(()=>{
      getData()
    },[])
  return (
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumbs title="Home" breadcrumbItem="RealEstate" />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="rowDiv spaceBetween w-100 alignCenter">
                  <CardTitle className="h4">Real-Estates</CardTitle>
                  <CardTitle
                    className="btn btn-success color-white"
                    onClick={()=>navigate("/RealEstate/add")}
                    style={{ color: "white" }}
                  >
                    Add Estate
                  </CardTitle>
                </div>
                <ModalDataDatatables data={data} getData={getData}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RealEstate;
